import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
 
  {
    path: 'dashboard',
    loadChildren: () => import('./objects/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'customdashboard',
    loadChildren: () => import('./objects/customdashboard/customdashboard.module').then(m => m.CustomDashboardPagePageModule)
  },
  {
    path: 'material/create',
    loadChildren: () => import('./objects/material/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'material/home-material',
    loadChildren: () => import('./objects/material/home-material/home-material.module').then(m => m.HomeMaterialPageModule)
  },
  {
    path: 'article/home-article',
    loadChildren: () => import('./objects/article/home-article/home-article.module').then(m => m.HomeArticlePageModule)
  },
  {
    path: 'article/create',
    loadChildren: () => import('./objects/article/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'customer/create',
    loadChildren: () => import('./objects/customer/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'customer/home-customer',
    loadChildren: () => import('./objects/customer/home-customer/home-customer.module').then(m => m.HomeCustomerPageModule)
  },
  {
    path: 'vendor/home-vendor',
    loadChildren: () => import('./objects/vendor/home-vendor/home-vendor.module').then(m => m.HomeVendorPageModule)
  },
  {
    path: 'vendor/create',
    loadChildren: () => import('./objects/vendor/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'article-hierarchy/create',
    loadChildren: () => import('./objects/articleHierarchy/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'article-hierarchy/home-article-hierarchy',
    loadChildren: () => import('./objects/articleHierarchy/home-article-hierarchy/home-article-hierarchy.module').then(m => m.HomeArticleHierarchyPageModule)
  },
  {
    path: 'gl/home-gl',
    loadChildren: () => import('./objects/gl/home-gl/home-gl.module').then(m => m.HomeGlPageModule)
  },
  {
    path: 'routing/home-routing',
    loadChildren: () => import('./objects/routing/home-routing/home-routing.module').then(m => m.HomeRoutingPageModule)
  },
  {
    path: 'bom/home-bom',
    loadChildren: () => import('./objects/bom/home-bom/home-bom.module').then(m => m.HomeBomPageModule)
  },
  {
    path: 'gl/create',
    loadChildren: () => import('./objects/gl/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'profit-center/home-profit-center',
    loadChildren: () => import('./objects/profitCenter/home-profit-center/home-profit-center.module').then(m => m.HomeProfitCenterPageModule)
  },
  {
    path: 'profit-center/create',
    loadChildren: () => import('./objects/profitCenter/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'supplier-portal/dashboard',
    loadChildren: () => import('./supplier-portal/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'supplier-portal/vendor-request',
    loadChildren: () => import('./supplier-portal/vendor-request/vendor-request.module').then(m => m.VendorRequestPageModule)
  },
  {
    path: 'material-modal',
    loadChildren: () => import('./modals/material-modal/material-modal.module').then(m => m.MaterialModalPageModule)
  },
  {
    path: 'routing/create',
    loadChildren: () => import('./objects/routing/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'costCenter/home-cost-center',
    loadChildren: () => import('./objects/costCenter/home-cost-center/home-cost-center.module').then(m => m.HomeCostCenterPageModule)
  },
  {
    path: 'costCenter/create',
    loadChildren: () => import('./objects/costCenter/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'salesOrder/create',
    loadChildren: () => import('./transactional-data/sales-order/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'salesOrder/home-sales-order',
    loadChildren: () => import('./transactional-data/sales-order/home-sales-order/home-sales-order.module').then( m => m.HomeSalesOrderPageModule)
  },
  {
    path: 'settings/workflow-setup',
    loadChildren: () => import('./settings/workflow-setup/workflow-setup.module').then( m => m.WorkflowSetupPageModule)
  },
  {
    path: 'settings/user-setup',
    loadChildren: () => import('./settings/user-setup/user-setup.module').then( m => m.UserSetupPageModule)
  },
  {
    path: 'settings/user-groups',
    loadChildren: () => import('./settings/user-groups/user-groups.module').then( m => m.UserGroupsPageModule)
  },
  {
    path: 'settings/add-user-group/:id',
    loadChildren: () => import('./settings/add-user-group/add-user-group.module').then( m => m.AddUserGroupPageModule)
  },
  {
    path: 'salesOrderStaging/home-sales-order-staging',
    loadChildren: () => import('./transactional-data/sales-order-staging/home-sales-order-staging/home-sales-order-staging.module').then( m => m.HomeSalesOrderStagingPageModule)
  },
  {
    path: 'salesOrderStaging/create',
    loadChildren: () => import('./transactional-data/sales-order-staging/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path : 'supplier-portal/vendor-registration/:id/:bankFile/:invoiceFile',
    loadChildren : () => import('./supplier-portal/vendor-registration/vendor-registration.module').then(m=>m.VendorRegistrationPageModule)
  },
  // {
  //   path : 'supplier-portal/vendor-update-request',
  //   loadChildren : () => import('./supplier-portal/vendor-update-request/vendor-update-request.module').then(m=>m.VendorUpdateRequestPageModule)
  // },
  {
    path : 'supplier-portal/vendor-unblock',
    loadChildren : () => import('./supplier-portal/vendor-unblock/vendor-unblock.module').then(m=>m.VendorUnblockPageModule)
  },
  {
    path : 'supplier-portal/vendor-update-request/:id/:bankFile/:invoiceFile',
    loadChildren : () => import('./supplier-portal/vendor-update-request/vendor-update-request.module').then(m=>m.VendorUpdateRequestPageModule)
  },
  {
    path : 'supplier-portal/carrier-vendor-request',
    loadChildren : () => import('./supplier-portal/carrier-vendor-request/carrier-vendor-request.module').then(m=>m.CarrierVendorRequestPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'site/home-site',
    loadChildren: () => import('./objects/site/home-site/home-site.module').then( m => m.HomeSitePageModule)
  },
  {
    path: 'site/create',
    loadChildren: () => import('./objects/site/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./authentication/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'bom/create',
    loadChildren: () => import('./objects/bom/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'view-request',
    loadChildren: () => import('./components/view-request/view-request.module').then( m => m.ViewRequestPageModule)
  },
  {
    path: 'add-user/:id',
    loadChildren: () => import('./settings/add-user/add-user.module').then( m => m.AddUserPageModule)
  },
  {
    path: 'mass-upload-info',
    loadChildren: () => import('./components/mass-upload-info/mass-upload-info.module').then( m => m.MassUploadInfoPageModule)
  },
  {
    path: 'article-hierarchy-modal',
    loadChildren: () => import('./components/article-hierarchy-modal/article-hierarchy-modal.module').then( m => m.ArticleHierarchyModalPageModule)
  },
  {
    path: 'dmac/home',
    loadChildren: () => import('./dmac/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'material/create-extension',
    loadChildren: () => import('./objects/material/create-extension/create-extension.module').then( m => m.CreateExtensionPageModule)
  },
  {
    path: 'display-table/:primaryKey/:id',
    loadChildren: () => import('./dmac/display-table/display-table.module').then( m => m.DisplayTablePageModule)
  },
  {
    path: 'settings/control/material',
    loadChildren: () => import('./settings/control/material/material.module').then( m => m.MaterialPageModule)
  },
  {
    path: 'settings/control/home',
    loadChildren: () => import('./settings/control/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'column-splitter-modal',
    loadChildren: () => import('./dmac/modals/column-splitter-modal/column-splitter-modal.module').then( m => m.ColumnSplitterModalPageModule)
  },
  {
    path: 'string-resizer-modal',
    loadChildren: () => import('./dmac/modals/string-resizer-modal/string-resizer-modal.module').then( m => m.StringResizerModalPageModule)
  },
  {
    path: 'concat-column-modal',
    loadChildren: () => import('./dmac/modals/concat-column-modal/concat-column-modal.module').then( m => m.ConcatColumnModalPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./authentication/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./authentication/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'my-sqlmodal-page',
    loadChildren: () => import('./dmac/modals/my-sqlmodal-page/my-sqlmodal-page.module').then( m => m.MySQLModalPagePageModule)
  },
  {
    path: 'hanacloud-modal-page',
    loadChildren: () => import('./dmac/modals/hanacloud-modal-page/hanacloud-modal-page.module').then( m => m.HANACloudModalPagePageModule)
  },
  {
    path: 'settings/profile',
    loadChildren: () => import('./settings/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'display-db-table/:primaryKey/:id',
    loadChildren: () => import('./dmac/display-db-table/display-db-table.module').then( m => m.DisplayDbTablePageModule)
  },
  {
    path: 'display-hana-db-table/:primaryKey/:id',
    loadChildren: () => import('./dmac/display-hana-db-table/display-hana-db-table.module').then( m => m.DisplayHanaDbTablePageModule)
  },
  {
    path: 'db-column-splitter-modal',
    loadChildren: () => import('./dmac/modals/db-column-splitter-modal/db-column-splitter-modal.module').then( m => m.DbColumnSplitterModalPageModule)
  },
  {
    path: 'db-concat-column-modal',
    loadChildren: () => import('./dmac/modals/db-concat-column-modal/db-concat-column-modal.module').then( m => m.DbConcatColumnModalPageModule)
  },
  {
    path: 'db-string-resizer-modal',
    loadChildren: () => import('./dmac/modals/db-string-resizer-modal/db-string-resizer-modal.module').then( m => m.DbStringResizerModalPageModule)
  },
  {
    path: 'hana-db',
    loadChildren: () => import('./dmac/modals/hana-db/hana-db.module').then( m => m.HanaDbPageModule)
  },
  {
    path: 'sql-db',
    loadChildren: () => import('./dmac/modals/sql-db/sql-db.module').then( m => m.SqlDbPageModule)
  },
  {
    path: 'uppercase-modal',
    loadChildren: () => import('./dmac/modals/uppercase-modal/uppercase-modal.module').then( m => m.UppercaseModalPageModule)
  },
  {
    path: 'lowercase-modal',
    loadChildren: () => import('./dmac/modals/lowercase-modal/lowercase-modal.module').then( m => m.LowercaseModalPageModule)
  },
  {
    path: 'titlecase-modal',
    loadChildren: () => import('./dmac/modals/titlecase-modal/titlecase-modal.module').then( m => m.TitlecaseModalPageModule)
  },
  {
    path: 'db-uppercase-modal',
    loadChildren: () => import('./dmac/modals/db-uppercase-modal/db-uppercase-modal.module').then( m => m.DbUppercaseModalPageModule)
  },
  {
    path: 'db-lowercase-modal',
    loadChildren: () => import('./dmac/modals/db-lowercase-modal/db-lowercase-modal.module').then( m => m.DbLowercaseModalPageModule)
  },
  {
    path: 'db-titlecase-modal',
    loadChildren: () => import('./dmac/modals/db-titlecase-modal/db-titlecase-modal.module').then( m => m.DbTitlecaseModalPageModule)
  },
  {
    path: 'dmac/profiling/:currentTableName/:id',
    loadChildren: () => import('./dmac/profiling/profiling.module').then( m => m.ProfilingPageModule)
  },
  {
    path: 'supplier-portal/dashboard-supplier',
    loadChildren: () => import('./supplier-portal/dashboard-supplier/dashboard-supplier.module').then( m => m.DashboardSupplierPageModule)
  },
  {
    path: 'supplier-portal/supplier-onboarding',
    loadChildren: () => import('./supplier-portal/supplier-onboarding/supplier-onboarding.module').then( m => m.SupplierOnboardingPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./authentication/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'mdg/material',
    loadChildren: () => import('./MDG/material/material.module').then( m => m.MaterialPageModule)
  },
  {
    path: 'create',
    loadChildren: () => import('./MDG/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'create-extension',
    loadChildren: () => import('./MDG/create-extension/create-extension.module').then( m=> m.CreateExtensionPageModule)
  },
  {
    path: 'article/print-preview',
    loadChildren: () => import('./objects/article/print-preview/print-preview.module').then( m => m.PrintPreviewPageModule)
  },
  {
    path: 'view-table',
    loadChildren: () => import('./dmac/modals/view-table/view-table.module').then( m => m.ViewTablePageModule)
  },
  {
    path: 'view-primary-keys',
    loadChildren: () => import('./dmac/modals/view-primary-keys/view-primary-keys.module').then( m => m.ViewPrimaryKeysPageModule)
  },
  {
    path: 'idmx',
    loadChildren: () => import('./dmac/modals/idmx/idmx.module').then( m => m.IdmxPageModule)
  },
  {
    path: 'agricultural/dashboard',
    loadChildren: () => import('./agricultural/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'mass-upload-validate',
    loadChildren: () => import('./components/mass-upload-validate/mass-upload-validate.module').then( m => m.MassUploadValidatePageModule)
  },
  {
    path: 'invoice-generate',
    loadChildren: () => import('./supplier-portal/invoice-generate/invoice-generate.module').then( m => m.InvoiceGeneratePageModule)
  },
  {
    path: 'create-invoice',
    loadChildren: () => import('./components/create-invoice/create-invoice.module').then( m => m.CreateInvoicePageModule)
  },
  {
    path: 'dashboard-invoice',
    loadChildren: () => import('./supplier-portal/dashboard-invoice/dashboard-invoice.module').then( m => m.DashboardInvoicePageModule)
  },
  {
    path: 'material/material-registration/:id',
    loadChildren: () => import('./objects/material/material-registration/material-registration.module').then( m => m.MaterialRegistrationPageModule)
  },
  {
    path: 'vendor/vendor-registration/:id',
    loadChildren: () => import('./objects/vendor/vendor-registration/vendor-registration.module').then( m => m.VendorRegistrationPageModule)
  },
  {
    path: 'customer/customer-registration/:id',
    loadChildren: () => import('./objects/customer/customer-registration/customer-registration.module').then( m => m.CustomerRegistrationPageModule)
  },
  {
    path: 'article/article-registration/:id/:imageattachment1/:imageattachment2/:imageattachment3',
    loadChildren: () => import('./objects/article/article-registration/article-registration.module').then( m => m.ArticleRegistrationPageModule)
  },
  {
    path: 'gl/gl-registration/:id',
    loadChildren: () => import('./objects/gl/gl-registration/gl-registration.module').then( m => m.GlRegistrationPageModule)
  },
  {
    path: 'costCenter/cost-center-registration/:id',
    loadChildren: () => import('./objects/costCenter/cost-center-registration/cost-center-registration.module').then( m => m.CostCenterRegistrationPageModule)
  },
  {
    path: 'profit-center/profit-center-registration/:id',
    loadChildren: () => import('./objects/profitCenter/profit-center-registration/profit-center-registration.module').then( m => m.ProfitCenterRegistrationPageModule)
  },
  {
    path: 'bom/bom-registration/:id',
    loadChildren: () => import('./objects/bom/bom-registration/bom-registration.module').then( m => m.BomRegistrationPageModule)
  },
  {
    path: 'routing/routing-registration/:id',
    loadChildren: () => import('./objects/routing/routing-registration/routing-registration.module').then( m => m.RoutingRegistrationPageModule)
  },
  {
    path: 'agro/create',
    loadChildren: () => import('./objects/Agro-Material/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'home-agro-material',
    loadChildren: () => import('./objects/Agro-Material/home-agro-material/home-agro-material.module').then( m => m.HomeAgroMaterialPageModule)
  },
  {
    path: 'supplier-heirarchy-home',
    loadChildren: () => import('./supplier-portal/dummy-screens/supplier-heirarchy-home/supplier-heirarchy-home.module').then( m => m.SupplierHeirarchyHomePageModule)
  },
  {
    path: 'supplier-hierarchy-createp',
    loadChildren: () => import('./supplier-portal/dummy-screens/supplier-hierarchy-createp/supplier-hierarchy-createp.module').then( m => m.SupplierHierarchyCreatepPageModule)
  },
  {
    path: 'view-details',
    loadChildren: () => import('./components/view-details/view-details.module').then( m => m.ViewDetailsPageModule)
  },
  {
    path: 'consolidate-data',
    loadChildren: () => import('./supplier-portal/dummy-screens/consolidate-data/consolidate-data.module').then( m => m.ConsolidateDataPageModule)
  },
  {
    path: 'hierarchy-approval',
    loadChildren: () => import('./supplier-portal/dummy-screens/hierarchy-approval/hierarchy-approval.module').then( m => m.HierarchyApprovalPageModule)
  },
  {
    path: 'profiling',
    loadChildren: () => import('./supplier-portal/dummy-screens/profiling/profiling.module').then( m => m.ProfilingPageModule)
  },
  {
    path: 'dmac-details',
    loadChildren: () => import('./components/dmac-details/dmac-details.module').then( m => m.DmacDetailsPageModule)
  },
  {
    path: 'show-request',
    loadChildren: () => import('./supplier-portal/dummy-screens/show-request/show-request.module').then( m => m.ShowRequestPageModule)
  },
  {
    path: 'supplier-hierarchy-dashboard',
    loadChildren: () => import('./supplier-portal/dummy-screens/supplier-hierarchy-dashboard/supplier-hierarchy-dashboard.module').then( m => m.SupplierHierarchyDashboardPageModule)
  },
  {
    path: 'sales-register',
    loadChildren: () => import('./components/sales-register/sales-register.module').then( m => m.SalesRegisterPageModule)
  },
  {
    path: 'audit-log',
    loadChildren: () => import('./components/audit-log/audit-log.module').then( m => m.AuditLogPageModule)
  },
  {
    path: 'produts-overview',
    loadChildren: () => import('./objects/plm/produts-overview/produts-overview.module').then( m => m.ProdutsOverviewPageModule)
  },
  {
    path: 'otpvarify/:id',
    loadChildren: () => import('./authentication/otpvarify/otpvarify.module').then( m => m.OtpvarifyPageModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import('./authentication/pricing/pricing.module').then( m => m.PricingPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./authentication/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('./authentication/billing/billing.module').then( m => m.BillingPageModule)
  },
  {
    path: 'user-details',
    loadChildren: () => import('./authentication/user-details/user-details.module').then( m => m.UserDetailsPageModule)
  },
  {
    path: 'customer-onboarding',
    loadChildren: () => import('./settings/customer-onboarding/customer-onboarding.module').then( m => m.CustomerOnboardingPageModule)
  },
  {
    path: 'partner-home',
    loadChildren: () => import('./objects/partner/partner-home/partner-home.module').then( m => m.PartnerHomePageModule)
  },
  {
    path: 'payment-success',
    loadChildren: () => import('./authentication/payment-success/payment-success.module').then( m => m.PaymentSuccessPageModule)
  },
  {
    path: 'payment-reject',
    loadChildren: () => import('./authentication/payment-reject/payment-reject.module').then( m => m.PaymentRejectPageModule)
  },
  {
    path: 'partnerpopup',
    loadChildren: () => import('./objects/partner/partnerpopup/partnerpopup.module').then( m => m.PartnerpopupPageModule)
  },
  {
    path: 'dmac/btp-is-logs',
    loadChildren: () => import('./dmac/btp-is-logs/btp-is-logs.module').then( m => m.BtpIsLogsPageModule)
  },
  {
    path: 'dmac/cpi-logs',
    loadChildren: () => import('./dmac/cpi-logs/cpi-logs.module').then( m => m.CpiLogsPageModule)
  },
  {
    path: 'article/article-listing/:id',
    loadChildren: () => import('./objects/article/article-listing/article-listing.module').then( m => m.ArticleListingPageModule)
  },
  {
    path: 'article-onboarding',
    loadChildren: () => import('./objects/article/article-onboarding/article-onboarding.module').then( m => m.ArticleOnboardingPageModule)
  },
  {
    path: 'system1',
    loadChildren: () => import('./underarmour/system1/system1.module').then( m => m.System1PageModule)
  },
  {
    path: 'system2',
    loadChildren: () => import('./underarmour/system2/system2.module').then( m => m.System2PageModule)
  },
  {
    path: 'system3',
    loadChildren: () => import('./underarmour/system3/system3.module').then( m => m.System3PageModule)
  },
  {
    path: 'system4',
    loadChildren: () => import('./underarmour/system4/system4.module').then( m => m.System4PageModule)
  },
  {
    path: 'system5',
    loadChildren: () => import('./underarmour/system5/system5.module').then( m => m.System5PageModule)
  },
  {
    path: 'system-report',
    loadChildren: () => import('./underarmour/system-report/system-report.module').then( m => m.SystemReportPageModule)
  },
  {
    path: 'pr-creation',
    loadChildren: () => import('./objects/walmart/pr-creation/pr-creation.module').then( m => m.PrCreationPageModule)
  },
  {
    path: 'salesInvoice/create',
    loadChildren: () => import('./objects/salesInvoice/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'home-invoice',
    loadChildren: () => import('./objects/salesInvoice/home-invoice/home-invoice.module').then( m => m.HomeInvoicePageModule)
  },
  // {
  //   path: 'inclusion',
  //   loadChildren: () => import('./objects/inclusion/inclusion.module').then( m => m.InclusionPageModule)
  // },
  {
    path: 'inclusion-home',
    loadChildren: () => import('./objects/inclu/inclusion-home/inclusion-home.module').then( m => m.InclusionHomePageModule)
  },
  {
    path: 'inclusion-create',
    loadChildren: () => import('./objects/inclu/inclusion-create/inclusion-create.module').then( m => m.InclusionCreatePageModule)
  },
  {
    path: 'display-afs-table/:id/:sheetName',
    loadChildren: () => import('./dmac/display-afs-table/display-afs-table.module').then( m => m.DisplayAfsTablePageModule)
  },
  {
    path: 'price-create',
    loadChildren: () => import('./objects/priceMaster/price-create/price-create.module').then( m => m.PriceCreatePageModule)
  },
  {
    path: 'price-home',
    loadChildren: () => import('./objects/priceMaster/price-home/price-home.module').then( m => m.PriceHomePageModule)
  },
  {
    path: 'price-create-from/:id',
    loadChildren: () => import('./objects/priceMaster/price-create-from/price-create-from.module').then( m => m.PriceCreateFromPageModule)
  },

{

    path: 'hts-create',
    loadChildren: () => import('./objects/hts/hts-create/hts-create.module').then( m => m.HtsCreatePageModule)
  },
  {
    path: 'hts-home',
    loadChildren: () => import('./objects/hts/hts-home/hts-home.module').then( m => m.HtsHomePageModule)
  },
  {
    path: 'pim/create',
    loadChildren: () => import('./objects/pim/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'pim/home-pim',
    loadChildren: () => import('./objects/pim/home-pim/home-pim.module').then( m => m.HomePimPageModule)
  },
  {
    path: 'pim/pim-registration/:id/:imageattachment1/:imageattachment2/:imageattachment3',
    loadChildren: () => import('./objects/pim/pim-registration/pim-registration.module').then( m => m.PimRegistrationPageModule)
  },
  {
    path: 'pim/pim-approve/:id/:imageattachment1/:imageattachment2/:imageattachment3',
    loadChildren: () => import('./objects/pim/pim-approve/pim-approve.module').then( m => m.PimApprovePageModule)
  },
  {
    path: 'pim/pim-audit-log',
    loadChildren: () => import('./objects/pim/pim-audit-log/pim-audit-log.module').then( m => m.PimAuditLogPageModule)
  },
  {
    path: 'purschase-price-home',
    loadChildren: () => import('./objects/priceMaster/purschase-price-home/purschase-price-home.module').then( m => m.PurschasePriceHomePageModule)
  },
  {
    path: 'purschase-price-create',
    loadChildren: () => import('./objects/priceMaster/purschase-price-create/purschase-price-create.module').then( m => m.PurschasePriceCreatePageModule)
  },
  {
    path: 'purschase-price-create-from/:id',
    loadChildren: () => import('./objects/priceMaster/purschase-price-create-from/purschase-price-create-from.module').then( m => m.PurschasePriceCreateFromPageModule)
  },
  {
    path: 'pim/pim-approve/:id/:imageattachment1/:imageattachment2/:imageattachment3',
    loadChildren: () => import('./objects/pim/pim-approve/pim-approve.module').then( m => m.PimApprovePageModule)
  },
  {
    path: 'pim/pim-audit-log',
    loadChildren: () => import('./objects/pim/pim-audit-log/pim-audit-log.module').then( m => m.PimAuditLogPageModule)
  },
 
  {
    path: 'dashboard',
    loadChildren: () => import('./objects/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'customdashboard',
    loadChildren: () => import('./objects/customdashboard/customdashboard.module').then(m => m.CustomDashboardPagePageModule)
  },
  {
    path: 'material/create',
    loadChildren: () => import('./objects/material/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'material/home-material',
    loadChildren: () => import('./objects/material/home-material/home-material.module').then(m => m.HomeMaterialPageModule)
  },
  {
    path: 'article/home-article',
    loadChildren: () => import('./objects/article/home-article/home-article.module').then(m => m.HomeArticlePageModule)
  },
  {
    path: 'article/create',
    loadChildren: () => import('./objects/article/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'customer/create',
    loadChildren: () => import('./objects/customer/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'customer/home-customer',
    loadChildren: () => import('./objects/customer/home-customer/home-customer.module').then(m => m.HomeCustomerPageModule)
  },
  {
    path: 'vendor/home-vendor',
    loadChildren: () => import('./objects/vendor/home-vendor/home-vendor.module').then(m => m.HomeVendorPageModule)
  },
  {
    path: 'vendor/create',
    loadChildren: () => import('./objects/vendor/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'article-hierarchy/create',
    loadChildren: () => import('./objects/articleHierarchy/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'article-hierarchy/home-article-hierarchy',
    loadChildren: () => import('./objects/articleHierarchy/home-article-hierarchy/home-article-hierarchy.module').then(m => m.HomeArticleHierarchyPageModule)
  },
  {
    path: 'gl/home-gl',
    loadChildren: () => import('./objects/gl/home-gl/home-gl.module').then(m => m.HomeGlPageModule)
  },
  {
    path: 'routing/home-routing',
    loadChildren: () => import('./objects/routing/home-routing/home-routing.module').then(m => m.HomeRoutingPageModule)
  },
  {
    path: 'bom/home-bom',
    loadChildren: () => import('./objects/bom/home-bom/home-bom.module').then(m => m.HomeBomPageModule)
  },
  {
    path: 'gl/create',
    loadChildren: () => import('./objects/gl/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'profit-center/home-profit-center',
    loadChildren: () => import('./objects/profitCenter/home-profit-center/home-profit-center.module').then(m => m.HomeProfitCenterPageModule)
  },
  {
    path: 'profit-center/create',
    loadChildren: () => import('./objects/profitCenter/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'supplier-portal/dashboard',
    loadChildren: () => import('./supplier-portal/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'supplier-portal/vendor-request',
    loadChildren: () => import('./supplier-portal/vendor-request/vendor-request.module').then(m => m.VendorRequestPageModule)
  },
  {
    path: 'material-modal',
    loadChildren: () => import('./modals/material-modal/material-modal.module').then(m => m.MaterialModalPageModule)
  },
  {
    path: 'routing/create',
    loadChildren: () => import('./objects/routing/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'costCenter/home-cost-center',
    loadChildren: () => import('./objects/costCenter/home-cost-center/home-cost-center.module').then(m => m.HomeCostCenterPageModule)
  },
  {
    path: 'costCenter/create',
    loadChildren: () => import('./objects/costCenter/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'salesOrder/create',
    loadChildren: () => import('./transactional-data/sales-order/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'salesOrder/home-sales-order',
    loadChildren: () => import('./transactional-data/sales-order/home-sales-order/home-sales-order.module').then( m => m.HomeSalesOrderPageModule)
  },
  {
    path: 'settings/workflow-setup',
    loadChildren: () => import('./settings/workflow-setup/workflow-setup.module').then( m => m.WorkflowSetupPageModule)
  },
  {
    path: 'settings/user-setup',
    loadChildren: () => import('./settings/user-setup/user-setup.module').then( m => m.UserSetupPageModule)
  },
  {
    path: 'settings/user-groups',
    loadChildren: () => import('./settings/user-groups/user-groups.module').then( m => m.UserGroupsPageModule)
  },
  {
    path: 'settings/add-user-group/:id',
    loadChildren: () => import('./settings/add-user-group/add-user-group.module').then( m => m.AddUserGroupPageModule)
  },
  {
    path: 'salesOrderStaging/home-sales-order-staging',
    loadChildren: () => import('./transactional-data/sales-order-staging/home-sales-order-staging/home-sales-order-staging.module').then( m => m.HomeSalesOrderStagingPageModule)
  },
  {
    path: 'salesOrderStaging/create',
    loadChildren: () => import('./transactional-data/sales-order-staging/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path : 'supplier-portal/vendor-registration/:id/:bankFile/:invoiceFile',
    loadChildren : () => import('./supplier-portal/vendor-registration/vendor-registration.module').then(m=>m.VendorRegistrationPageModule)
  },
  // {
  //   path : 'supplier-portal/vendor-update-request',
  //   loadChildren : () => import('./supplier-portal/vendor-update-request/vendor-update-request.module').then(m=>m.VendorUpdateRequestPageModule)
  // },
  {
    path : 'supplier-portal/vendor-unblock',
    loadChildren : () => import('./supplier-portal/vendor-unblock/vendor-unblock.module').then(m=>m.VendorUnblockPageModule)
  },
  {
    path : 'supplier-portal/vendor-update-request/:id/:bankFile/:invoiceFile',
    loadChildren : () => import('./supplier-portal/vendor-update-request/vendor-update-request.module').then(m=>m.VendorUpdateRequestPageModule)
  },
  {
    path : 'supplier-portal/carrier-vendor-request',
    loadChildren : () => import('./supplier-portal/carrier-vendor-request/carrier-vendor-request.module').then(m=>m.CarrierVendorRequestPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'site/home-site',
    loadChildren: () => import('./objects/site/home-site/home-site.module').then( m => m.HomeSitePageModule)
  },
  {
    path: 'site/create',
    loadChildren: () => import('./objects/site/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./authentication/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'bom/create',
    loadChildren: () => import('./objects/bom/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'view-request',
    loadChildren: () => import('./components/view-request/view-request.module').then( m => m.ViewRequestPageModule)
  },
  {
    path: 'add-user/:id',
    loadChildren: () => import('./settings/add-user/add-user.module').then( m => m.AddUserPageModule)
  },
  {
    path: 'mass-upload-info',
    loadChildren: () => import('./components/mass-upload-info/mass-upload-info.module').then( m => m.MassUploadInfoPageModule)
  },
  {
    path: 'article-hierarchy-modal',
    loadChildren: () => import('./components/article-hierarchy-modal/article-hierarchy-modal.module').then( m => m.ArticleHierarchyModalPageModule)
  },
  {
    path: 'dmac/home',
    loadChildren: () => import('./dmac/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'material/create-extension',
    loadChildren: () => import('./objects/material/create-extension/create-extension.module').then( m => m.CreateExtensionPageModule)
  },
  {
    path: 'display-table/:primaryKey/:id',
    loadChildren: () => import('./dmac/display-table/display-table.module').then( m => m.DisplayTablePageModule)
  },
  {
    path: 'settings/control/material',
    loadChildren: () => import('./settings/control/material/material.module').then( m => m.MaterialPageModule)
  },
  {
    path: 'settings/control/home',
    loadChildren: () => import('./settings/control/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'column-splitter-modal',
    loadChildren: () => import('./dmac/modals/column-splitter-modal/column-splitter-modal.module').then( m => m.ColumnSplitterModalPageModule)
  },
  {
    path: 'string-resizer-modal',
    loadChildren: () => import('./dmac/modals/string-resizer-modal/string-resizer-modal.module').then( m => m.StringResizerModalPageModule)
  },
  {
    path: 'concat-column-modal',
    loadChildren: () => import('./dmac/modals/concat-column-modal/concat-column-modal.module').then( m => m.ConcatColumnModalPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./authentication/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./authentication/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'my-sqlmodal-page',
    loadChildren: () => import('./dmac/modals/my-sqlmodal-page/my-sqlmodal-page.module').then( m => m.MySQLModalPagePageModule)
  },
  {
    path: 'hanacloud-modal-page',
    loadChildren: () => import('./dmac/modals/hanacloud-modal-page/hanacloud-modal-page.module').then( m => m.HANACloudModalPagePageModule)
  },
  {
    path: 'settings/profile',
    loadChildren: () => import('./settings/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'display-db-table/:primaryKey/:id',
    loadChildren: () => import('./dmac/display-db-table/display-db-table.module').then( m => m.DisplayDbTablePageModule)
  },
  {
    path: 'display-hana-db-table/:primaryKey/:id',
    loadChildren: () => import('./dmac/display-hana-db-table/display-hana-db-table.module').then( m => m.DisplayHanaDbTablePageModule)
  },
  {
    path: 'db-column-splitter-modal',
    loadChildren: () => import('./dmac/modals/db-column-splitter-modal/db-column-splitter-modal.module').then( m => m.DbColumnSplitterModalPageModule)
  },
  {
    path: 'db-concat-column-modal',
    loadChildren: () => import('./dmac/modals/db-concat-column-modal/db-concat-column-modal.module').then( m => m.DbConcatColumnModalPageModule)
  },
  {
    path: 'db-string-resizer-modal',
    loadChildren: () => import('./dmac/modals/db-string-resizer-modal/db-string-resizer-modal.module').then( m => m.DbStringResizerModalPageModule)
  },
  {
    path: 'hana-db',
    loadChildren: () => import('./dmac/modals/hana-db/hana-db.module').then( m => m.HanaDbPageModule)
  },
  {
    path: 'sql-db',
    loadChildren: () => import('./dmac/modals/sql-db/sql-db.module').then( m => m.SqlDbPageModule)
  },
  {
    path: 'uppercase-modal',
    loadChildren: () => import('./dmac/modals/uppercase-modal/uppercase-modal.module').then( m => m.UppercaseModalPageModule)
  },
  {
    path: 'lowercase-modal',
    loadChildren: () => import('./dmac/modals/lowercase-modal/lowercase-modal.module').then( m => m.LowercaseModalPageModule)
  },
  {
    path: 'titlecase-modal',
    loadChildren: () => import('./dmac/modals/titlecase-modal/titlecase-modal.module').then( m => m.TitlecaseModalPageModule)
  },
  {
    path: 'db-uppercase-modal',
    loadChildren: () => import('./dmac/modals/db-uppercase-modal/db-uppercase-modal.module').then( m => m.DbUppercaseModalPageModule)
  },
  {
    path: 'db-lowercase-modal',
    loadChildren: () => import('./dmac/modals/db-lowercase-modal/db-lowercase-modal.module').then( m => m.DbLowercaseModalPageModule)
  },
  {
    path: 'db-titlecase-modal',
    loadChildren: () => import('./dmac/modals/db-titlecase-modal/db-titlecase-modal.module').then( m => m.DbTitlecaseModalPageModule)
  },
  {
    path: 'dmac/profiling/:currentTableName/:id',
    loadChildren: () => import('./dmac/profiling/profiling.module').then( m => m.ProfilingPageModule)
  },
  {
    path: 'supplier-portal/dashboard-supplier',
    loadChildren: () => import('./supplier-portal/dashboard-supplier/dashboard-supplier.module').then( m => m.DashboardSupplierPageModule)
  },
  {
    path: 'supplier-portal/supplier-onboarding',
    loadChildren: () => import('./supplier-portal/supplier-onboarding/supplier-onboarding.module').then( m => m.SupplierOnboardingPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./authentication/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'mdg/material',
    loadChildren: () => import('./MDG/material/material.module').then( m => m.MaterialPageModule)
  },
  {
    path: 'create',
    loadChildren: () => import('./MDG/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'create-extension',
    loadChildren: () => import('./MDG/create-extension/create-extension.module').then( m=> m.CreateExtensionPageModule)
  },
  {
    path: 'article/print-preview',
    loadChildren: () => import('./objects/article/print-preview/print-preview.module').then( m => m.PrintPreviewPageModule)
  },
  {
    path: 'view-table',
    loadChildren: () => import('./dmac/modals/view-table/view-table.module').then( m => m.ViewTablePageModule)
  },
  {
    path: 'view-primary-keys',
    loadChildren: () => import('./dmac/modals/view-primary-keys/view-primary-keys.module').then( m => m.ViewPrimaryKeysPageModule)
  },
  {
    path: 'idmx',
    loadChildren: () => import('./dmac/modals/idmx/idmx.module').then( m => m.IdmxPageModule)
  },
  {
    path: 'agricultural/dashboard',
    loadChildren: () => import('./agricultural/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'mass-upload-validate',
    loadChildren: () => import('./components/mass-upload-validate/mass-upload-validate.module').then( m => m.MassUploadValidatePageModule)
  },
  {
    path: 'invoice-generate',
    loadChildren: () => import('./supplier-portal/invoice-generate/invoice-generate.module').then( m => m.InvoiceGeneratePageModule)
  },
  {
    path: 'create-invoice',
    loadChildren: () => import('./components/create-invoice/create-invoice.module').then( m => m.CreateInvoicePageModule)
  },
  {
    path: 'dashboard-invoice',
    loadChildren: () => import('./supplier-portal/dashboard-invoice/dashboard-invoice.module').then( m => m.DashboardInvoicePageModule)
  },
  {
    path: 'material/material-registration/:id',
    loadChildren: () => import('./objects/material/material-registration/material-registration.module').then( m => m.MaterialRegistrationPageModule)
  },
  {
    path: 'vendor/vendor-registration/:id',
    loadChildren: () => import('./objects/vendor/vendor-registration/vendor-registration.module').then( m => m.VendorRegistrationPageModule)
  },
  {
    path: 'customer/customer-registration/:id',
    loadChildren: () => import('./objects/customer/customer-registration/customer-registration.module').then( m => m.CustomerRegistrationPageModule)
  },
  {
    path: 'article/article-registration/:id',
    loadChildren: () => import('./objects/article/article-registration/article-registration.module').then( m => m.ArticleRegistrationPageModule)
  },
  {
    path: 'gl/gl-registration/:id',
    loadChildren: () => import('./objects/gl/gl-registration/gl-registration.module').then( m => m.GlRegistrationPageModule)
  },
  {
    path: 'costCenter/cost-center-registration/:id',
    loadChildren: () => import('./objects/costCenter/cost-center-registration/cost-center-registration.module').then( m => m.CostCenterRegistrationPageModule)
  },
  {
    path: 'profit-center/profit-center-registration/:id',
    loadChildren: () => import('./objects/profitCenter/profit-center-registration/profit-center-registration.module').then( m => m.ProfitCenterRegistrationPageModule)
  },
  {
    path: 'bom/bom-registration/:id',
    loadChildren: () => import('./objects/bom/bom-registration/bom-registration.module').then( m => m.BomRegistrationPageModule)
  },
  {
    path: 'routing/routing-registration/:id',
    loadChildren: () => import('./objects/routing/routing-registration/routing-registration.module').then( m => m.RoutingRegistrationPageModule)
  },
  {
    path: 'agro/create',
    loadChildren: () => import('./objects/Agro-Material/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'home-agro-material',
    loadChildren: () => import('./objects/Agro-Material/home-agro-material/home-agro-material.module').then( m => m.HomeAgroMaterialPageModule)
  },
  {
    path: 'supplier-heirarchy-home',
    loadChildren: () => import('./supplier-portal/dummy-screens/supplier-heirarchy-home/supplier-heirarchy-home.module').then( m => m.SupplierHeirarchyHomePageModule)
  },
  {
    path: 'supplier-hierarchy-createp',
    loadChildren: () => import('./supplier-portal/dummy-screens/supplier-hierarchy-createp/supplier-hierarchy-createp.module').then( m => m.SupplierHierarchyCreatepPageModule)
  },
  {
    path: 'view-details',
    loadChildren: () => import('./components/view-details/view-details.module').then( m => m.ViewDetailsPageModule)
  },
  {
    path: 'consolidate-data',
    loadChildren: () => import('./supplier-portal/dummy-screens/consolidate-data/consolidate-data.module').then( m => m.ConsolidateDataPageModule)
  },
  {
    path: 'hierarchy-approval',
    loadChildren: () => import('./supplier-portal/dummy-screens/hierarchy-approval/hierarchy-approval.module').then( m => m.HierarchyApprovalPageModule)
  },
  {
    path: 'profiling',
    loadChildren: () => import('./supplier-portal/dummy-screens/profiling/profiling.module').then( m => m.ProfilingPageModule)
  },
  {
    path: 'dmac-details',
    loadChildren: () => import('./components/dmac-details/dmac-details.module').then( m => m.DmacDetailsPageModule)
  },
  {
    path: 'show-request',
    loadChildren: () => import('./supplier-portal/dummy-screens/show-request/show-request.module').then( m => m.ShowRequestPageModule)
  },
  {
    path: 'supplier-hierarchy-dashboard',
    loadChildren: () => import('./supplier-portal/dummy-screens/supplier-hierarchy-dashboard/supplier-hierarchy-dashboard.module').then( m => m.SupplierHierarchyDashboardPageModule)
  },
  {
    path: 'sales-register',
    loadChildren: () => import('./components/sales-register/sales-register.module').then( m => m.SalesRegisterPageModule)
  },
  {
    path: 'audit-log',
    loadChildren: () => import('./components/audit-log/audit-log.module').then( m => m.AuditLogPageModule)
  },
  {
    path: 'produts-overview',
    loadChildren: () => import('./objects/plm/produts-overview/produts-overview.module').then( m => m.ProdutsOverviewPageModule)
  },
  {
    path: 'otpvarify/:id',
    loadChildren: () => import('./authentication/otpvarify/otpvarify.module').then( m => m.OtpvarifyPageModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import('./authentication/pricing/pricing.module').then( m => m.PricingPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./authentication/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('./authentication/billing/billing.module').then( m => m.BillingPageModule)
  },
  {
    path: 'user-details',
    loadChildren: () => import('./authentication/user-details/user-details.module').then( m => m.UserDetailsPageModule)
  },
  {
    path: 'customer-onboarding',
    loadChildren: () => import('./settings/customer-onboarding/customer-onboarding.module').then( m => m.CustomerOnboardingPageModule)
  },
  {
    path: 'partner-home',
    loadChildren: () => import('./objects/partner/partner-home/partner-home.module').then( m => m.PartnerHomePageModule)
  },
  {
    path: 'payment-success',
    loadChildren: () => import('./authentication/payment-success/payment-success.module').then( m => m.PaymentSuccessPageModule)
  },
  {
    path: 'payment-reject',
    loadChildren: () => import('./authentication/payment-reject/payment-reject.module').then( m => m.PaymentRejectPageModule)
  },
  {
    path: 'partnerpopup',
    loadChildren: () => import('./objects/partner/partnerpopup/partnerpopup.module').then( m => m.PartnerpopupPageModule)
  },
  {
    path: 'dmac/btp-is-logs',
    loadChildren: () => import('./dmac/btp-is-logs/btp-is-logs.module').then( m => m.BtpIsLogsPageModule)
  },
  {
    path: 'dmac/cpi-logs',
    loadChildren: () => import('./dmac/cpi-logs/cpi-logs.module').then( m => m.CpiLogsPageModule)
  },
  {
    path: 'article/article-listing/:id',
    loadChildren: () => import('./objects/article/article-listing/article-listing.module').then( m => m.ArticleListingPageModule)
  },
  {
    path: 'article-onboarding',
    loadChildren: () => import('./objects/article/article-onboarding/article-onboarding.module').then( m => m.ArticleOnboardingPageModule)
  },
  {
    path: 'system1',
    loadChildren: () => import('./underarmour/system1/system1.module').then( m => m.System1PageModule)
  },
  {
    path: 'system2',
    loadChildren: () => import('./underarmour/system2/system2.module').then( m => m.System2PageModule)
  },
  {
    path: 'system3',
    loadChildren: () => import('./underarmour/system3/system3.module').then( m => m.System3PageModule)
  },
  {
    path: 'system4',
    loadChildren: () => import('./underarmour/system4/system4.module').then( m => m.System4PageModule)
  },
  {
    path: 'system5',
    loadChildren: () => import('./underarmour/system5/system5.module').then( m => m.System5PageModule)
  },
  {
    path: 'system-report',
    loadChildren: () => import('./underarmour/system-report/system-report.module').then( m => m.SystemReportPageModule)
  },
  {
    path: 'pr-creation',
    loadChildren: () => import('./objects/walmart/pr-creation/pr-creation.module').then( m => m.PrCreationPageModule)
  },
  {
    path: 'salesInvoice/create',
    loadChildren: () => import('./objects/salesInvoice/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'home-invoice',
    loadChildren: () => import('./objects/salesInvoice/home-invoice/home-invoice.module').then( m => m.HomeInvoicePageModule)
  },
  // {
  //   path: 'inclusion',
  //   loadChildren: () => import('./objects/inclusion/inclusion.module').then( m => m.InclusionPageModule)
  // },
  {
    path: 'inclusion-home',
    loadChildren: () => import('./objects/inclu/inclusion-home/inclusion-home.module').then( m => m.InclusionHomePageModule)
  },
  {
    path: 'inclusion-create',
    loadChildren: () => import('./objects/inclu/inclusion-create/inclusion-create.module').then( m => m.InclusionCreatePageModule)
  },
  {
    path: 'display-afs-table/:id/:sheetName',
    loadChildren: () => import('./dmac/display-afs-table/display-afs-table.module').then( m => m.DisplayAfsTablePageModule)
  },
  {
    path: 'price-create',
    loadChildren: () => import('./objects/priceMaster/price-create/price-create.module').then( m => m.PriceCreatePageModule)
  },
  {
    path: 'price-home',
    loadChildren: () => import('./objects/priceMaster/price-home/price-home.module').then( m => m.PriceHomePageModule)
  },
  {
    path: 'price-create-from/:id',
    loadChildren: () => import('./objects/priceMaster/price-create-from/price-create-from.module').then( m => m.PriceCreateFromPageModule)
  },

{

    path: 'hts-create',
    loadChildren: () => import('./objects/hts/hts-create/hts-create.module').then( m => m.HtsCreatePageModule)
  },
  {
    path: 'hts-home',
    loadChildren: () => import('./objects/hts/hts-home/hts-home.module').then( m => m.HtsHomePageModule)
  },
  {
    path: 'pim/create',
    loadChildren: () => import('./objects/pim/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'pim/home-pim',
    loadChildren: () => import('./objects/pim/home-pim/home-pim.module').then( m => m.HomePimPageModule)
  },
  {
    path: 'pim/pim-registration/:id/:imageattachment1/:imageattachment2/:imageattachment3',
    loadChildren: () => import('./objects/pim/pim-registration/pim-registration.module').then( m => m.PimRegistrationPageModule)
  },
  {
    path: 'purschase-price-home',
    loadChildren: () => import('./objects/priceMaster/purschase-price-home/purschase-price-home.module').then( m => m.PurschasePriceHomePageModule)
  },
  {
    path: 'purschase-price-create',
    loadChildren: () => import('./objects/priceMaster/purschase-price-create/purschase-price-create.module').then( m => m.PurschasePriceCreatePageModule)
  },
  {
    path: 'purschase-price-create-from/:id',
    loadChildren: () => import('./objects/priceMaster/purschase-price-create-from/purschase-price-create-from.module').then( m => m.PurschasePriceCreateFromPageModule)
  },
  {
    path: 'pim/pim-approve/:id/:imageattachment1/:imageattachment2/:imageattachment3',
    loadChildren: () => import('./objects/pim/pim-approve/pim-approve.module').then( m => m.PimApprovePageModule)
  },
  {
    path: 'view-sales-price',
    loadChildren: () => import('./components/view-sales-price/view-sales-price.module').then( m => m.ViewSalesPricePageModule)
  },
  {
    path: 'view-purchase-price',
    loadChildren: () => import('./components/view-purchase-price/view-purchase-price.module').then( m => m.ViewPurchasePricePageModule)
  },
  {
    path: 'supplier-home',
    loadChildren: () => import('./objects/langTransfer/supplier-home/supplier-home.module').then( m => m.SupplierHomePageModule)
  },
  {
    path: 'display-ecc-table/:id',
    loadChildren: () => import('./dmac/display-ecc-table/display-ecc-table.module').then( m => m.DisplayEccTablePageModule)
  },
  {
    path: 'consolidated-data',
    loadChildren: () => import('./dmac/consolidated-data/consolidated-data.module').then( m => m.ConsolidatedDataPageModule)
  },
  {
    path: 's4-hana-data',
    loadChildren: () => import('./dmac/s4-hana-data/s4-hana-data.module').then( m => m.S4HanaDataPageModule)
  },
  {
    path: 'consolidated-data-table/:id',
    loadChildren: () => import('./dmac/consolidated-data-table/consolidated-data-table.module').then( m => m.ConsolidatedDataTablePageModule)
  },
  {
    path: 'hana-table-data',
    loadChildren: () => import('./dmac/hana-table-data/hana-table-data.module').then( m => m.HanaTableDataPageModule)
  },
  {
    path: 'recipe/create',
    loadChildren: () => import('./objects/recipe/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'recipe/home-recipe',
    loadChildren: () => import('./objects/recipe/home-recipe/home-recipe.module').then( m => m.HomeRecipePageModule)
  },
  {
    path: 'recipe/recipe-registration/:id',
    loadChildren: () => import('./objects/recipe/recipe-registration/recipe-registration.module').then( m => m.RecipeRegistrationPageModule)
  }

  







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
